<template>
  <img alt="My logo" src="./assets/logo3.png" />
  <Hanssoft_fun msg="" />
  <div style="text-align: center; margin-top: 20px;"><a href="https://beian.miit.gov.cn" target="_blank">鲁ICP备2024111949号</a></div>
</template>

<script>
import Hanssoft_fun from "./components/Hanssoft.fun.vue";

export default {
  name: "App",
  components: {
    Hanssoft_fun,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
img {
  max-width: 200px;
  max-height: 200px;
}
</style>
