<template>
  <div class="hello">
    <h3>Develop Links</h3>
    <!-- <ul>
      <li><a href="https://docker.hanssoft.cn:88" target="_blank" rel="noopener">Harbor</a></li>
      <li><a href="https://nexus.hanssoft.cn:88" target="_blank" rel="noopener">Nexus</a></li>
      <li><a href="https://git.hanssoft.cn:88" target="_blank" rel="noopener">Gitlab</a></li>
    </ul>
    <h3>Production Links</h3>
    <ul>
      <li><a href="https://note.hanssoft.cn:88" target="_blank" rel="noopener">Leanote</a></li>
      <li><a href="https://sf.hanssoft.cn:88" target="_blank" rel="noopener">Seafile</a></li>
      <li><a href="https://blog.hanssoft.cn:88" target="_blank" rel="noopener">Halo blog</a></li>
    </ul>
    <h3>Life Style</h3>
    <ul>
      <li><a href="https://ebook.hanssoft.cn:88" target="_blank" rel="noopener">Calibre</a></li>
      <li><a href="https://film.hanssoft.cn:88" target="_blank" rel="noopener">Jellyfin</a></li>
      <li><a href="https://memory.hanssoft.cn:88" target="_blank" rel="noopener">Photoprism</a></li>
    </ul> -->
  </div>
</template>

<script>
export default {
  name: 'Hanssoft_fun',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
